import React from "react";
import Helmet from "react-helmet";

import * as styles from "./index.module.scss";

import { Col, Collapse, Row } from "antd";

import Footer from "@components/footer";
import Header from "@components/header";
import TextCenterBanner from "@components/textCenterBanner";

import phoneImg from "@src/images/serviceRules/Rectangle4386.png";
import pcImg from "@src/images/serviceRules/Rectangle4387.png";

const renderDatas = [
  {
    title: "1.账户条款",
    details: [
      "1. 您必须年满18周岁或至少达到在您居住或使用本服务的法定年龄。",
      "2. 您必须取得您居住地或使用本服务所在地的合法经营者资质，包括但不限于已取得营业执照和完成相关税务登记，保证遵守您当地的合法经营性质。 ",
      "3. 要访问和使用该服务，您必须提供您的完整姓名，现住址，电话号码，有效的电子邮件地址，以及我们所需要的其他信息，且确保信息的真实性，否则我们有权利拒绝您的服务申请或随时终止对您的服务。",

      "4. 您同意将使用您提供的电子邮件地址作为主要沟通方式。 ",
      "5. 您有责任确保您的账户密码安全。如果您未能保持帐户和密码的安全性，Shoprise不会对任何损失或损害承担责任。 ",
      "6. 您需要对您账户上的活动负责，包括但不限于：上传，收集，生成，存储，显示，分发，传输或展示的材料（包括图像、照片、图形、音频、视频、书写内容、代码、信息或数据） 。",
      "7. 违反本条款中的任何条款，我们有权单方面终止协议停止对您的服务。 ",
    ],
    intro: [
      "您对自己的帐户以及上传到Shoprise的所有材料负责。请注意，如果违反这些条款，我们将取消您的服务。 ",
      "如果我们需要联系您，我们会向您发送电子邮件。",
    ],
  },
  {
    title: "2.账户激活",
    details: [
      "2.1 Shoprise账户 ",
      "1. 根据第2.1.2节的规定，本条款的目的，注册服务的人是账户所有者，并且将被授权使用我们提供给账户所有者的相关服务。",
      "2. 如果您代表您的雇主注册服务，则您的雇主应为账户所有者。如果您代表您的雇主注册服务，则您声明并保证您的雇主受此《Shoprise服务条款》的约束。",
    ],
    anotherDetails: [
      "2.2 Checkout账户 ",
      "1. 我们已与多家收款渠道公司合作。您可以在Shoprise后台设置页面里选择收款方式，根据顾客的支付需求选择并设置其他种类的收款渠道。",
      "2. 为了避免歧义，收款渠道属于第三方服务，在本条款的第11条有定义。",
    ],
  },
  {
    title: "3.通用条款",
    details: [
      " 1. 在您成为Shoprise会员前，您必须阅读、同意并且接受本服务条款中包含的所有条款和条件，包括《Shoprise使用协议》和《隐私政策》。",
      "2. 服务条款适用于中华人民共和国法律，并按照中华人民共和国法律解释，不考虑法律冲突的原则。双方确认任何因本条款引发的争议或索赔均由中华人民共和国浙江省杭州市有管辖权的法院管辖。 ",
      "3. 您承认并同意Shoprise可随时通过在Shoprise网站上发布相关修订和重述的服务条款来修改本条款，条款的此类修订服务自发布之日起生效。在修订后的服务条款发布到Shoprise的网站后，您继续使用服务即表示您同意并接受修订后的服务条款。如果您不同意对服务条款进行任何更改，请不要继续使用本服务。 ",
      "4. 您不得将Shoprise服务用于任何非法或未经授权的目的，也不得在使用本服务时违反您所在司法辖区的任何法律（包括但不限于版权法），适用于您客户所在司法辖区的法律，或者中国的法律。您将遵守使用本服务时的所有适用法律法规。 ",
      "5. 您不得购买Shoprise、Shoprise商标以及其变体或错误拼写的域名和搜索引擎或其他点击付费关键词。",

      "6. 未经Shoprise书面许可，您不得翻版、复制、拷贝、出售、转售或为未授权第三方通过本服务的任何部分获利（包括但不限于本服务的使用以及访问）。 ",
      "7. 有关服务条款的问题应发送至 service@shoprise.com。 ",
      "8. 您知晓您的资料（不包括信用卡信息）可能未经加密转移，并涉及（a）通过各种网络传输；（b）为符合和适应连接网络或设备技术要求作出的改变。信用卡信息在网络传输过程中始终是加密的。",
      "9. 你认可并同意，您在使用本服务中涉及的信息，包括传送给Shoprise或者由Shoprise存储的信息，均受《隐私政策》的保护和约束。 ",
      "10. 本条款可能会有除中文以外的其他版本。如果不同语言版本的服务条款之间存在任何矛盾或冲突，以最新的中文版《Shoprise服务条款》为准。 ",
      "11.《Shoprise服务条款》的所有条款和规定对《Shoprise服务条款》双方及其各自的继承人、继任者、获准受让人和法定代表人具有约束力，并对其有利。Shoprise有权在不通知您或未经您同意的情况下转让这些服务条款。未经Shoprise事先书面同意，您无权将本服务条款或您的本协议下的任何权利或义务转让或以其他方式转让给第三方。 ",
    ],
    intro: [
      "您不得将Shoprise的服务用于任何非法目的。 ",
      "如果存在争议，将在中国浙江省杭州市有管辖权的法院处理。 ",
      "您的资料转移的时候未经加密，可能会被更改，但信用卡的信息是始终加密的。",
    ],
  },
  {
    title: "4.Shoprise权利",
    details: [
      "1. 我们保留以任何理由修改或终止服务的权利，恕不另行通知。",
      "2. 我们保留随时以任何理由拒绝向任何人提供服务的权利。",
      "3. 如果我们发现店铺提供的商品或服务或者发布到店铺的资料违反了Shoprise的《Shoprise使用协议》，我们将会移除资料并暂停或终止其账户。 ",
      "4. Shoprise不会预先筛选资料，我们可以自行决定拒绝或删除服务中的任何资料。",
      "5. 我们保留向您的竞争对手提供服务的权利，并且不承诺在任何特定细分市场中享有独家经营权。您进一步认可并且同意Shoprise合作商也可能是Shoprise的客户或商家，并且他们可能会与您竞争。",
      "6. 如果有关帐户所有权的争议，我们保留要求提供文件以确定或确认帐户所有权的权利。文件可能包括但不限于您的营业执照扫描件，政府签发的带照片的身份证件，信用卡的最后四位数字等。 ",
      "7. 我们有权判定合法账户的所有权，并有权将账户转让给合法的账户所有者。如果我们无法判定账户合法所有者，Shoprise将保留暂时禁用此账户的权利，直至争端双方确定解决方案为止。 ",
    ],
    intro: [
      "我们可以随时修改，取消或拒绝服务。",
      "如果发生Shoprise账户的所有权纠纷，我们可以冻结账户或将其转让给合法所有者。",
    ],
  },
  {
    title: "5.责任限制",
    details: [
      "1. 您明确理解并同意，Shoprise不承担任何因为使用或无法使用该服务导致的直接、间接、偶然的、特殊的、从属性的或惩罚性的损失，包括但不限于因使用或无法使用该服务带来的收益、商誉、数据或其他无形的损失。 ",
      "2. 在任何情况下，Shoprise不承担与我们的网站、服务或本《Shoprise服务条款》（包括因为过失引起的）有关所产生的利润损失或任何特殊的、偶然的或附属性的损失。您应确保Shoprise公司、Shoprise的高级职员、董事、代理人、员工和供应商免于因为您违反本《Shoprise服务条款》或其他附属文件（包括《Shoprise使用协议》），或您违反任何法律或第三方的权利而被起诉带来的赔偿要求，并同意赔偿我们已支付的相关诉讼费和损害赔偿，包括合理的律师费。 ",
      "3. 您自行承担使用本服务的风险。本服务以“现状”和“可用”为基础提供，不附带任何明示，暗示或法定的保证或条件。",
      "4. Shoprise不保证服务不会中断，及时，安全或无错误。",
      "5. Shoprise不保证使用本服务可能获得的结果是准确可靠的。 ",
      "6. Shoprise不保证您通过本服务购买或获得的任何产品，服务，信息或其他材料的质量符合您的期望，或者本服务中的任何错误都将得到纠正。 ",
    ],

    intro: [
      "如果您违反法律，违反本条款或第三方的权利，或者是您被起诉，我们概不负责。 ",
      "服务以“现状”提供给您，可能会有错误或者中断，我们不提供任何担保。",
    ],
  },
  {
    title: "6.放弃和完成协议",
    details: [
      "Shoprise未能行使或执行服务条款的任何权利或规定，并不视为对此类权利或规定的放弃。服务条款及其通过引用合并的文件构成您与Shoprise之间的完整协议，并管辖您对本服务的使用，取代您与Shoprise之间的任何先前协议（包括但不限于任何之前版本的条款、服务）。 ",
    ],
    intro: [
      "如果Shoprise任何时候没有执行任何这些条款，并不意味着Shoprise会放弃该权利。所有服务条款构成适用于您的协议。如果您与Shoprise之间的任何协议与这些条款冲突，则以本条款为主。",
    ],
  },
  {
    title: "7.知识产权和客户内容",
    details: [
      "1. 您保留您提供给Shoprise服务的所有资料的知识产权。您上传的所有资料都是您的。您可以随时删除您的账户以删除您的Shoprise店铺。 ",
      "2. 在上传资料时，您同意：（a）允许其他互联网用户查看您公开发布到您店铺的资料；（b）允许Shoprise存储，展示您的资料（对于您公开发布的资料）；（c）Shoprise可以在任何时候检查提交给其服务的任何资料，但Shoprise没有义务一定这样做。 ",
      "3. 您保留您上传到Shoprise店铺的所有资料的所有权；但是，为了让您的店铺公开，您同意并允许其他人查看您公开发布到店铺的资料。您有责任保证这些资料遵守任何适用的法律或法规。 ",
      "4. 我们不会将您的保密信息透露给第三方，除非我们在提供服务的过程中有此要求。保密信息包括您向我们提供的任何未公开的资料或信息。保密信息不包括：(a)在我们收到该信息时就已经公开的信息；（b）在我们收到该信息之后被公开（但不是由于我们的原因被公开）；（c）在不违反我们或其保密义务的情况下，我们从除你之外的其他人收到的信息；或者（d）法律要求我们披露的信息。 ",
      "5. Shoprise拥有使用与您店铺相关的名称、注册商标、服务标志和品牌徽标去营销Shoprise服务的非排他性的权利和许可。 ",
    ],
    intro: [" 您上传的任何东西都是您的，您需要对这些东西负所有责任。"],
  },
  {
    title: "8.主题商店",
    details: [
      "1. 您可以通过装饰并使用Shoprise主题商店里的设计模板建立您自己的Shoprise店铺。您可以选择添加主题商店里的模板到您的店铺。 ",
      "2. 您可以选择修改主题以适合您的店铺。Shoprise可以酌情添加或修改用于引用Shoprise的页脚。Shoprise也可以自行决定修改其中违反《Shoprise使用协议》或《Shoprise服务条款》的内容。Shoprise可以根据需要修改主题以反映技术更改和更新。 ",
      "3. 主题的知识产权是属于设计师的。如果您超出购买主题所授予的权利，设计师可能会对您采取法律行动，Shoprise可能会采取行政措施，例如修改您的商店或关闭您的商店。 ",
      "4. 确保安装的新模板不会覆盖或者破坏用户当前或预存的主题或用户界面是用户的责任，Shoprise不承担任何的损失或损害。 ",
    ],
    intro: [
      " 您可以选择添加模板到您的Shoprise店铺。您可以随意修改装饰主题以适用您的店铺，但必须尊重模板所有者的权利。请注意，随着时间推移，主题可能会被更新修改或者消失。",
    ],
  },
  {
    title: "9.取消和终止",
    details: [
      "1. 您可以随时通过发送电子邮件至service@shoprise.com取消您的帐户， 然后按照Shoprise的回复中向您指示的具体说明进行操作。 ",
      "2. 任何一方因任何原因终止服务时： ",
      "• Shoprise将停止向您提供服务，您将无法再访问您的帐户; ",
      "• 除非服务条款另有规定，否则您无权要求退还或按比例退还任何费用; ",
      "• 在终止生效之日，您因使用Shoprise服务而欠的任何未结余额将立即到期且您须立即全额支付; ",
      "• 您的商店网站将脱机。",
      "3. 当您的店铺存在未结算费用时，Shoprise有权暂停您店铺在Shoprise上的部分或全部权限和功能；若您在30天内仍未完成结算的，Shoprise将立即清除您店铺的所有数据并终止向您提供服务。 ",
      "4. 我们保留以任何理由修改或终止Shoprise服务或您的帐户的权利，恕不另行通知; ",
      "5. 欺诈：如果我们怀疑您涉嫌任何欺诈行为，Shoprise将会暂停或终止您的账户。 ",
    ],
    intro: [
      "如果要终止账号，请发邮件到service@shoprise.com。Shoprise将回复您终止账户的具体步骤。 ",
      "我们可能随时修改或取消您的账户。如果您涉嫌任何欺诈，我们将暂停或终止您的账户。",
    ],
  },
  {
    title: "10.服务和价格的修改",
    details: [
      "1. Shoprise保留随时修改或终止本服务（或其中任何部分）的权利，恕不另行通知。 ",
      "2. Shoprise保留在通知或不通知的情况下随时修改或终止服务（或其任何部分）的权利。 ",
      "3. Shoprise不对您或任何第三方因为本服务的任何修改、价格变更、暂停或中断负责。 ",
    ],
    intro: [" 我们可以随时更改或终止服务，不承担任何责任。"],
  },
  {
    title: "11.第三方服务",
    details: [
      "1. 除了这些服务条款之外，您同意并遵守适用于您购买或由第三方提供的服务的附加服务特定条款。 ",
      "2. Shoprise可能会不时推荐，为您提供访问或启用第三方软件，应用程序，产品，服务或网站链接（统称为“第三方服务”）供您考虑或使用，包括通过Shoprise应用或主页。此类第三方服务仅为方便起见而提供您购买，访问或使用任何此类第三方服务仅限于您与适用的第三方服务提供商（“第三方服务提供商”）之间。除了这些服务条款之外，您还同意受适用于您从第三方供应商处购买或由第三方供应商提供的服务的其他服务特定条款的约束。 ",
      "3. 我们不提供有关第三方服务的任何保证。您认可Shoprise无法控制第三方服务，因此对使用此类第三方服务的任何人不承担任何责任。Shoprise不保证第三方服务的可用性，您认可Shoprise可能随时自行决定禁止访问任何第三方服务，且无需通知您。Shoprise不会因为暂停访问或禁用某第三方服务对任何人承担任何责任。 ",
      "4. 如果您在使用本服务时安装或启用了第三方服务，则您授权允许适用的第三方提供商访问您的数据、采取必要的用于与第三方和本服务之间的交互操作。任何您与第三方提供商之间的数据交换和其他交互仅限于您与此类第三方供应商之间。由于第三方服务和第三方提供商可以对您的数据和其他资料进行访问，Shoprise对于您的数据或其他资料的披露、修改或删除，或者您可能遭受的任何相应的损失或损害不承担任何责任。 ",
      "5. 在任何情况下，Shoprise均不对由于任何第三方服务或您与第三方提供商之间的合作关系所造成的任何直接、间接、偶然、特殊、惩罚性、或其他损害负责。上述限制应在适用法律允许的最大范围内适用。 ",
      "6. 您与任何第三方提供商之间的关系严格限制在您与此类第三方提供商之间，Shoprise没有义务介入您与第三方提供商之间发生的任何争议。 ",
      ",7. 您同意赔偿并使我们和（如适用）我们的母公司，子公司，关联公司，Shoprise合作伙伴，管理人员，董事，代理人，员工和供应商免于因您的使用而产生的任何索赔或要求，包括合理的律师费第三方服务或您与第三方提供商的关系。 ",
    ],
    intro: [
      "我们不对第三方服务负责，因此使用它们需要您自担风险。如果您在Shoprise平台上使用任何第三方服务，则允许我们将您的数据发送到这些服务。如果您使用它们，您同意我们不提供服务保障，因此请事先咨询我们。",
    ],
  },
  {
    title: "12.测试服务",
    details: [
      "1. Shoprise可能会不时自行决定邀请您在试用版中使用正在开发但尚未向所有商家提供的预发布或测试版功能。 ",
      "2. 测试服务可能受其他条款和条件的约束，Shoprise将在您使用测试服务之前向您提供这些条款和条件。 ",
      "3. 此类测试服务以及与之相关的所有相关对话和材料将被视为Shoprise机密信息，并受本协议中的保密规定的约束。 ",
      "4. 在不限制前述内容的一般性的前提下，您同意未经Shoprise事先书面同意，您不能发表任何公开声明或以其他方式披露您参与测试服务。 ",
      "5. Shoprise不对测试服务的运作作出任何陈述或保证。Shoprise可自行决定随时终止测试服务。对于因测试服务引起的或与之相关的任何伤害或损害，Shoprise概不负责。 ",
      "6. 测试服务可能与最终版本的工作方式不同。Shoprise可以自行决定是否更改或不发布测试服务的最终版本或商业版本。",
    ],
  },

  {
    title: "13.保密义务",
    details: [
      "  在您使用Shoprise服务的期间，您可能会获取到与Shoprise公司和/或Shoprise服务相关的但并未向公众披露的信息（“Shoprise保密信息”）。您同意：（a）Shoprise对Shoprise保密信息拥有专属权；（b）您不会讲Shoprise保密信息披露于任何第三方，除了向您关联方、雇员、承包商或代理 （“关联方”），此关联方同时受此保密义务和本条款的约束和限制；以及（c）您将通过所有合理措施确保Shoprise保密信息不在本条款允许范围外被披露。       ",
    ],
    intro: ["如果您收到Shoprise机密信息，则需要对其进行保护。 "],
  },
  {
    title: "14.隐私和数据保护",
    details: [
      "Shoprise非常重视对您和您用户的个人信息的隐私保护（“个人信息”）。通过使用Shoprise服务，您认可并同意Shoprise在收集、使用和披露这些个人信息时遵守《隐私政策》的约定。 ",
    ],
    intro: [" Shoprise对个人信息的收集和使用将遵守《隐私政策》的约定。"],
  },
  {
    title: "15.意见反馈",
    details: [
      "Shoprise欢迎任何有关改进或增补服务的想法或建议。在任何情况下，您披露的任何想法、建议或相关材料，对服务、第三方服务或第三方服务商的评价（统称“反馈”）都不是保密信息或您也没有获得赔偿的预期。通过向Shoprise提供反馈 （无论是直接提交给Shoprise还是任何Shoprise主办的论坛或拥有的页面上发布），您放弃反馈中所有的权利，并且Shoprise有权可以在未经您或任何第三方的许可的情况下根据需要自由实施和使用您所提供的反馈。您提交给Shoprise的针对第三方服务或第三方服务商的任何评论必须是您所知最准确的，并且不得是违法的，淫秽的，威胁的，诽谤的，侵犯隐私的，侵犯知识产权的，或以其他方式伤害第三方或令人反感的。Shoprise保留删除、编辑第三方服务或第三方服务商反馈的权利（但没有义务），Shoprise没有定期检查发布的反馈的义务。",
    ],
    date: ["最近更新日期：2021年05月31日", "生效日期：2021年05月31日"],
  },
];

const ServiceRulesPage = () => {
  return (
    <div className={styles.serviceRulesPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>服务条款-Shoprise 官网</title>
      </Helmet>
      <Header />

      <TextCenterBanner pcImg={pcImg} phoneImg={phoneImg} text="服务条款" />
      <Row>
        <Col xs={0} sm={0} md={24}>
          <div className={styles.list}>
            {renderDatas.map((item) => {
              return (
                <div className={styles.listItem} key={item.title}>
                  <div className={styles.itemTitle}>{item.title}</div>
                  {item.details.map((text, index) => (
                    <div className={styles.itemDetail} key={index}>
                      {text}
                    </div>
                  ))}
                  {item.intro && (
                    <div className={styles.intro}>
                      <div className={styles.introTitle}> 说明 </div>
                      {item.intro.map((intro) => {
                        return <div key={intro}>{intro}</div>;
                      })}
                    </div>
                  )}
                  {item.anotherDetails && (
                    <div className={styles.details2}>
                      {item.anotherDetails.map((text) => (
                        <div className={styles.itemDetail} key={text}>
                          {text}
                        </div>
                      ))}
                    </div>
                  )}

                  {item.date && (
                    <div className={styles.date}>
                      {item.date.map((date) => (
                        <div key={date}>{date}</div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Col>
        <Col xs={24} sm={24} md={0}>
          <div className={styles.smallList}>
            <Collapse
              defaultActiveKey={[0]}
              expandIconPosition="right"
              ghost
              bordered="false"
            >
              {renderDatas.map((item) => (
                <Collapse.Panel header={item.title} key={item.title}>
                  {/* <div className={styles.itemTitle}>{item.title}</div> */}
                  {item.details.map((text, index) => (
                    <div className={styles.itemDetail} key={index}>
                      {text}
                    </div>
                  ))}
                  {item.intro && (
                    <div className={styles.intro}>
                      <div className={styles.introTitle}> 说明 </div>
                      {item.intro.map((intro, index) => {
                        return <div key={intro}>{intro}</div>;
                      })}
                    </div>
                  )}
                  {item.anotherDetails && (
                    <div className={styles.details2}>
                      {item.anotherDetails.map((text) => (
                        <div className={styles.itemDetail} key={text}>
                          {text}
                        </div>
                      ))}
                    </div>
                  )}

                  {item.date && (
                    <div className={styles.date}>
                      {item.date.map((date) => (
                        <div key={date}>{date}</div>
                      ))}
                    </div>
                  )}
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default ServiceRulesPage;
