// extracted by mini-css-extract-plugin
export var date = "index-module--date--sJOM+";
export var details2 = "index-module--details2--IyCsh";
export var intro = "index-module--intro--C+RpP";
export var introTitle = "index-module--introTitle--ZRSfk";
export var itemDetail = "index-module--itemDetail--ZFMTp";
export var itemTitle = "index-module--itemTitle--orvmY";
export var list = "index-module--list--rXjhY";
export var listItem = "index-module--listItem--yW-EP";
export var serviceRulesPage = "index-module--serviceRulesPage--utWLi";
export var smallList = "index-module--smallList--Amh7Y";